import { auth } from "../firebase.js"; // Firebase authentication instance
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:5000/api";


// Get Firebase Authentication Token
const getToken = async () => {
  const user = auth.currentUser;
  if (!user) throw new Error("User not authenticated");

  try {
    return await user.getIdToken();
  } catch (error) {
    console.error("Error fetching token:", error.message);
    throw new Error("Failed to retrieve authentication token");
  }
};

const apiRequestWithRetry = async (endpoint, method = "GET", body = null, retries = 2) => {
  try {
    const token = await getToken();
    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
      method,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: body ? JSON.stringify(body) : null,
      timeout: 5000 // 5-second timeout
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`API Error: ${errorText}`);
    }

    return response.json();
  } catch (error) {
    if (retries > 0) {
      console.warn(`Retrying request to ${endpoint}. Attempts left: ${retries}`);
      return apiRequestWithRetry(endpoint, method, body, retries - 1);
    }
    throw error;
  
  }
 
};


// Update other methods to use apiRequestWithRetry
export const registerForEvent = (eventId) => 
  apiRequestWithRetry("/events/register", "POST", { eventId });


export const unregisterForEvent = (eventId) => 
  apiRequestWithRetry("/events/unregister", "POST", { eventId });

export const fetchEvents = () =>
  apiRequestWithRetry("/events", "GET");

export const fetchEventById = (eventId) =>
  apiRequestWithRetry(`/events/${eventId}`, "GET");

export const fetchRegisteredEvents = async (userId) => {
  try {
    console.log("Fetching registered events for user:", userId);
    const result = await apiRequestWithRetry(`/users/${userId}/registeredEvents`, "GET");
    console.log("Registered Events Result:", result);
    return result;
  } catch (error) {
    console.error("Error fetching registered events:", error.message);
    console.error("Full Error Object:", error);
    throw error;
  }
};

export const fetchPreferences = () =>
  apiRequestWithRetry("/preferences", "GET");

export const updatePreferences = (categories) =>
  apiRequestWithRetry("/preferences", "POST", { categories });


// Preload User Data (Preferences & Events)
export const preloadUserData = async () => {
  try {
    const [preferences, events] = await Promise.all([fetchPreferences(), fetchEvents()]);
    return { preferences, events };
  } catch (error) {
    console.error("Failed to preload user data:", error.message);
    throw error;
  }
};



