import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import './CalendarApp.css';
import Logo from './logo-race-flag.svg'; // Update the path as necessary

import ProfileLogout from "./ProfileLogout"; // Import the ProfileModal component
import { auth } from "../firebase.js"; // Import the `auth` object from your Firebase file
import { signOut } from "firebase/auth";
import {
  fetchPreferences,

  updatePreferences,
  fetchEvents,
  
  fetchRegisteredEvents,
  registerForEvent, 
  unregisterForEvent,
  
} from "./api"; // Import API functions

//test






const localizer = momentLocalizer(moment);


const CalendarApp = ({ user }) => {
  const [allEvents, setAllEvents] = useState([]); // Store all events
  const [events, setEvents] = useState([]); // Filtered events based on preferences
  const [error, setError] = useState(null);
  const [preferences, setPreferences] = useState({ categories: [] });
  const [registeredEvents, setRegisteredEvents] = useState([]); 
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null); // Event currently being viewed
  const [selectedTab, setSelectedTab] = useState("calendar"); // "calendar" or "map"
  const [usersRegisteredEvents, setUsersRegisteredEvents ] = useState([]);

  useEffect(()=>{
    if(!user) return;
    console.log('auth', usersRegisteredEvents)

    fetchRegisteredEvents(user.uid).then((events) => {
      
      setUsersRegisteredEvents(events);
    });
  }, [user])


  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = auth.currentUser;
        if (!user) throw new Error("User not authenticated");
  
        console.log("Fetching data for user:", user.uid);
  
        // Parallel fetching with Promise.all
        const [userPreferences, allEvents, registeredEventsObject] = await Promise.all([
          fetchPreferences(),
          fetchEvents(),
          fetchRegisteredEvents(user.uid),
        ]);
  
        console.log("User Preferences:", userPreferences);
        console.log("Registered Events Object:", registeredEventsObject);
  
        const registeredEventsData = Object.entries(registeredEventsObject || {})
          .map(([eventId, eventData]) => ({ eventId, ...eventData }));
  
        const userCategories = userPreferences?.categories || ["F1", "MotoGP", "IndyCar", "NASCAR"];
        
        // More efficient event filtering and mapping
        const mappedEvents = allEvents
          .filter(event => userCategories.includes(event.category))
          .map(event => ({
            ...event,
            isRegistered: registeredEventsData.some(regEvent => regEvent.eventId === event.id),
            start: new Date(event.start),
            end: new Date(event.end)
          }));

        console.log('registered events', registeredEventsData);
        console.log(mappedEvents)
  
        setPreferences({ categories: userCategories });
        setAllEvents(allEvents);
        setEvents(mappedEvents);
        setRegisteredEvents(registeredEventsData);
  
      } catch (error) {
        console.error("Full Error in fetchUserData:", error);
        setError(`Failed to load data: ${error.message}`);
      }
    };
    console.log("fetched user data")
    fetchUserData();
  }, []); // Empty dependency array
  
  
  
  
  
  
  
  
  
  
  useEffect(() => {
    console.log("Registered events on profile load:", registeredEvents);
  }, [registeredEvents]);
  
  
  
  
  
  


   // Update displayed events when preferences change
   useEffect(() => {
    const { categories } = preferences;
    console.log('t', usersRegisteredEvents)
    const filteredEvents = allEvents
      .filter((event) => categories.includes(event.category))
      .map(event => ({
        ...event,
        // title: event.title,
        // start: new Date(event.start),
        // end: new Date(event.end),
        // category: event.category,
        // id: event.id, // Include ID for URL linking
        isRegistered:!!usersRegisteredEvents[event.id]
      }));
  
    setEvents(filteredEvents);
  }, [preferences, allEvents, usersRegisteredEvents]);
  


  const handlePreferenceChange = async (category) => {
    try {
      const updatedCategories = preferences.categories.includes(category)
        ? preferences.categories.filter((cat) => cat !== category) // Remove category
        : [...preferences.categories, category]; // Add category
  
      await updatePreferences(updatedCategories); // Send only categories
      setPreferences({ ...preferences, categories: updatedCategories });
    } catch (error) {
      console.error("Error updating preferences:", error);
    }
  };
  
 
const handleEventClick = (event) => {
  const selectedEventFullData = events.filter((item) => item.id === event.id)[0];
  setSelectedEvent(selectedEventFullData);
};



  
  const handleLogout = async () => {
    try {
      await signOut(auth); // Adjust based on your Firebase setup
      console.log("User successfully logged out.");
      setShowProfileModal(false); // Close the modal after logout
    } catch (error) {
      console.error("Error logging out:", error.message);
    }
  };
  
  
 
  
  

  const CustomToolbar = (props) => {
    const goToBack = () => props.onNavigate("PREV");
    const goToNext = () => props.onNavigate("NEXT");
    const goToToday = () => props.onNavigate("TODAY");
  
    const handleViewChange = (view) => props.onView(view);

    // Get the current month or date depending on the view
  const currentTitle =
  props.view === "day"
    ? moment(props.date).format("dddd, MMMM Do YYYY") // e.g., "Tuesday, March 14th 2024"
    : moment(props.date).format("MMMM YYYY"); // e.g., "March 2024"

    
    return (
      
      <div className="toolbar">
        <div className="nav-buttons">
          <button className="nav-btn" onClick={goToBack}>
            Back
          </button>
          <button className="nav-btn" onClick={goToToday}>
            Today
          </button>
          <button className="nav-btn" onClick={goToNext}>
            Next
          </button>
        </div>
        <div className="current-month">
          <h2>{currentTitle}</h2>
        </div>
        <div className="view-buttons">
          <button
            className={`view-btn ${props.view === "month" ? "active" : ""}`}
            onClick={() => handleViewChange("month")}
          >
            Month
          </button>
          <button
            className={`view-btn ${props.view === "week" ? "active" : ""}`}
            onClick={() => handleViewChange("week")}
          >
            Week
          </button>
          <button
            className={`view-btn ${props.view === "day" ? "active" : ""}`}
            onClick={() => handleViewChange("day")}
          >
            Day
          </button>
        </div>
      </div>
      
    );
  };
  

 
  
  const eventStyleGetter = (event) => {
    const baseStyle = {
      backgroundColor: (() => {
        switch (event.category) {
          case "F1":
            return "#004225"; // British Racing Green
          case "MotoGP":
            return "#CD212A"; // Italian Racing Red
          case "IndyCar":
            return "#0055A4"; // French Racing Blue
          case "NASCAR":
            return "#C0C0C0"; // German Silver
          default:
            return "#FFA500"; // Default Orange
        }
      })(),
      borderRadius: "5px",
      opacity: 0.8,
      color: "white",
      border: "0px",
      display: "block",
      padding: "2px",
      transition: "background-color 0.3s ease",
    };
  
    // Attach event listeners for hover
    return {
      style: baseStyle,
      className: "custom-event", // Add this for global hover styles if needed
      onMouseEnter: (e) => {
        e.target.style.backgroundColor = "#FF2424"; // Hover: Red
      },
      onMouseLeave: (e) => {
        e.target.style.backgroundColor = baseStyle.backgroundColor; // Revert to original
      },
    };
  };
  

  

  return (

    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        background: "linear-gradient(to bottom, #424242, #000000)",
        color: "white",
      }}
    >
      <header
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "1rem",
          borderBottom: "1px solid #ccc",
          background: "transparent", // Allows the gradient to show through
        }}
      >
          <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={Logo}
            alt="Motorsport Logo"
            style={{ width: "40px", height: "40px", marginRight: "1rem" }}
          />
          <h1>Car Calendar</h1>
        </div>
        <button
          style={{
            background: "none",
            border: "none",
            cursor: "pointer",
            padding: "0",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => setShowProfileModal(true)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-2.4 -2.4 28.80 28.80"
            fill="none"
            stroke="#ffffff"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{ width: "40px", height: "40px" }}
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0">
              <rect
                x="-2.4"
                y="-2.4"
                width="28.80"
                height="28.80"
                rx="5.472"
                fill="#ff2424"
                strokeWidth="0"
              ></rect>
            </g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
            </g>
          </svg>
        </button>
      </header>
      <main
        style={{
          flexGrow: 1,
          padding: "1rem",
          filter: showProfileModal ? "blur(4px)" : "none",
          transition: "filter 0.3s ease",
        }}
      >
        {error ? (
          <p style={{ color: "red", textAlign: "center" }}>{error}</p>
        ) : (
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            onSelectEvent={handleEventClick} // Add this line
            components={{
              toolbar: CustomToolbar, // Use the custom toolbar
            }}
            style={{
              height: "100%",
              backgroundColor: "#1a1a1a", // Calendar background
              color: "white", // Text color
              borderRadius: "8px", // Rounded corners
              padding: "1rem",
            }}
            eventPropGetter={eventStyleGetter}
            dayPropGetter={(date) => {
              const style = {
                backgroundColor: "#1a1a1a", // Same background for all days
                color: "white", // Text color for day numbers
                border: "1px solid #333", // Border to separate days
              };
              return { style };
            }}
            slotPropGetter={() => {
              return {
                style: {
                  border: "none", // Remove borders from time slots
                  
                },
              };
            }}
            timeSlotWrapperProps={{
              style: {
                border: "none", // Remove vertical lines in time slots
              },
            }}
          />
        )}
        {selectedEvent && (
  <div
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
    }}
  >
    <div
      style={{
        backgroundColor: "#333",
        padding: "2rem",
        borderRadius: "8px",
        maxWidth: "400px",
        width: "100%",
        boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
        textAlign: "center",
      }}
    >
      <h2 style={{ color: "#FFD700" }}>{selectedEvent.title}</h2>
    <p>
      Start: {moment(selectedEvent.start).format("MMMM Do YYYY, h:mm A")}
      <br />
      End: {moment(selectedEvent.end).format("MMMM Do YYYY, h:mm A")}
    </p>
    <p>{JSON.stringify(selectedEvent)}</p>
    {new Date(selectedEvent.start) > new Date() ? (
  selectedEvent.isRegistered ? (
    <button
      style={{
        marginTop: "1rem",
        padding: "0.5rem 1rem",
        backgroundColor: "#004225", // Registered color
        color: "white",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
      }}
      onClick={async () => {
        try {
          await unregisterForEvent(selectedEvent.id);
          setSelectedEvent((prev) => ({
            ...prev,
            isRegistered: false,
          }));
          // Refresh the state after unregistration
          const updatedEvents = await fetchEvents();
          const userRegisteredEvents = await fetchRegisteredEvents(auth.currentUser.uid);
          setEvents(
            updatedEvents.map((event) => ({
              ...event,
              isRegistered: !!userRegisteredEvents[event.id],
            }))
          );
        } catch (error) {
          console.error("Error unregistering event:", error.message);
        }
      }}
    >
      Unregister
    </button>
  ) : (
    <button
      style={{
        marginTop: "1rem",
        padding: "0.5rem 1rem",
        backgroundColor: "#FF2424", // Unregistered color
        color: "white",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
      }}
      onClick={async () => {
        try {
          await registerForEvent(selectedEvent.id);
          setSelectedEvent((prev) => ({
            ...prev,
            isRegistered: true,
          }));
          // Refresh the state after registration
          const updatedEvents = await fetchEvents();
          const userRegisteredEvents = await fetchRegisteredEvents(auth.currentUser.uid);
          setEvents(
            updatedEvents.map((event) => ({
              ...event,
              isRegistered: !!userRegisteredEvents[event.id],
            }))
          );
        } catch (error) {
          console.error("Error registering event:", error.message);
        }
      }}
    >
      Register
    </button>
  )
) : (
  <p style={{ color: "#bbb", marginTop: "1rem" }}>
    You cannot register for past events.
  </p>
)}

    <button
      style={{
        marginTop: "1rem",
        marginLeft: "1rem",
        padding: "0.5rem 1rem",
        backgroundColor: "#555",
        color: "white",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
      }}
      onClick={() => setSelectedEvent(null)}
    >
      Close
    </button>
  </div>
</div>
)}


      </main>
  

      {showProfileModal && (
  <div
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0,0,0,0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
      padding: "1rem", // Added padding for small screens
    }}
  >
    <div
      style={{
        backgroundColor: "#333",
        padding: "2rem",
        borderRadius: "16px",
        maxWidth: "800px",
        width: "100%",
        boxShadow: "0px 8px 16px rgba(0,0,0,0.2)",
        display: "flex",
        flexDirection: "row",
        gap: "1.5rem", // Spacing between left and right panels
      }}
    >
      {/* Close Button */}
      <button
                style={{
                  position: "relative", // Lock to this container
                  top: "0rem",
                  right: "0rem",
                  backgroundColor: "#FF2424",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  width: "40px",
                  height: "40px",
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                  cursor: "pointer",
                  boxShadow: "0px 4px 8px rgba(0,0,0,0.2)",
                  transition: "background-color 0.3s ease",
                }}
                onClick={() => setShowProfileModal(false)}
                onMouseEnter={(e) => (e.target.style.backgroundColor = "#FF4040")}
                onMouseLeave={(e) => (e.target.style.backgroundColor = "#FF2424")}
              >
                ✖
              </button>
              
              <ProfileLogout onClose={() => setShowProfileModal(false)} onLogout={handleLogout} />

      {/* Left Side: Preferences */}
      <div>
  <h3 style={{ color: "#FF2424", marginBottom: "0.5rem" }}>Preferences</h3>
  {["F1", "MotoGP", "IndyCar", "NASCAR"].map((category) => (
    <label
      key={category}
      htmlFor={`${category}-checkbox`}
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "1rem",
        fontSize: "1rem",
        color: "white",
      }}
    >
      <input
        id={`${category}-checkbox`}
        name={`${category}-checkbox`}
        type="checkbox"
        checked={preferences.categories.includes(category)}
        onChange={() => handlePreferenceChange(category)}
        style={{
          marginRight: "0.5rem",
          transform: "scale(1.2)", // Slightly larger checkbox
        }}
      />
      {category}
    </label>
  ))}
</div>

                    

      {/* Right Side: Registered Events */}
      <div
        style={{
          flex: 1,
          backgroundColor: "#444",
          borderRadius: "12px",
          padding: "1rem",
          overflowY: "auto",
          maxHeight: "900px", // Scrollable height
          display: "flex",
          flexDirection: "column",
        }}
        
      >
        

        <h3 style={{ color: "#FFD700", marginBottom: "1rem" }}>Registered Events</h3>
  {registeredEvents.length > 0 ? (
    <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
      {registeredEvents.map((fakeEvent, index) => {
         const selectedEventFullData = events.filter((item) => item.id === fakeEvent.eventId)[0];
     
        const now = moment();
        const eventStart = moment(selectedEventFullData.start);
        const daysUntil = eventStart.diff(now, "days");

        return (
          <li
            key={index}
            style={{
              backgroundColor: "#555",
              borderRadius: "12px",
              margin: "0.5rem 0",
              padding: "1rem",
              color: "white",
              boxShadow: "0px 4px 8px rgba(0,0,0,0.2)",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <p style={{ margin: 0, fontWeight: "bold", fontSize: "1rem" }}>
              {fakeEvent.title}
            </p>
            <p style={{ margin: 0, fontSize: "0.9rem", color: "#ccc" }}>
              {moment(selectedEventFullData.start).format("MMMM Do YYYY, h:mm A")}
            </p>
            <p
              style={{
                marginTop: "0.5rem",
                fontSize: "0.9rem",
                color: "#FFD700",
              }}
            >
              {daysUntil > 0
                ? `${daysUntil} day${daysUntil > 1 ? "s" : ""} until the event`
                : "Event is today!"}
            </p>
            <p style={{ margin: 0, fontSize: "0.7rem" }}>{fakeEvent.category}</p>
          </li>
        );
      })}
    </ul>
  ) : (
    <p style={{ color: "#bbb", textAlign: "center" }}>No registered events.</p>
  )}
</div>
        </div>
      </div>
)}
</div>
);
};
export default CalendarApp;