import React, { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import SplashScreen from "./components/SplashScreen";
import Calendar from "./components/CalendarApp.jsx";

const App = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
     
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <Routes>
        {/* Redirect logged-in users to the calendar */}
        <Route path="/" element={user ? <Navigate to="/calendar" /> : <SplashScreen setUser={setUser} />} />
        {/* Calendar view */}
        <Route path="/calendar" element={user ? <Calendar user={user} isAuthenticated={user} /> : <Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default App;
