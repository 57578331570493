import React, { useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { auth } from "../firebase";

const SplashScreen = ({ setUser }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  

  // Register a new user
  const handleRegister = async () => {
    try {
      const newUser = await createUserWithEmailAndPassword(auth, email, password);
      setUser(newUser.user);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleLogin = async () => {
    try {
      // Step 1: Try to log in the user
      const loggedInUser = await signInWithEmailAndPassword(auth, email, password);
      setUser(loggedInUser.user);
      console.log("Log in success");
  
    } catch (loginError) {
      console.log("Log in fail:", loginError.message);
      setError(loginError.message);
    }
  };
  
  

  // Log in with Google
  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const googleUser = await signInWithPopup(auth, provider);
      setUser(googleUser.user);
      
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div style={{ textAlign: "center", padding: "2rem" }}>
      
      <h1>Welcome to Car Calendar</h1>
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleLogin}>Log In</button>
      <button onClick={handleRegister}>Register</button>
      <button onClick={handleGoogleLogin}>Log In with Google</button>
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

export default SplashScreen;
