import React from "react";




const ProfileLogout = ({ onLogout }) => {
  
  return (
    <div style={{flex: 1, padding: "2rem", backgroundColor: "#444", borderRadius: "12px", overflowY: "auto", textAlign: "center" }}>
      <h2>Profile</h2>
      <p>Currently Logged in</p>
      <button
        style={{
          marginTop: "1rem",
          padding: "0.5rem 1rem",
          backgroundColor: "#FF2424",
          color: "white",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
        }}
        onClick={onLogout}
      >
        Logout
      </button>
      
    </div>
  );
};

export default ProfileLogout;
